import { Link as GatsbyLink, graphql } from "gatsby"
import Image from "gatsby-image"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { AspectRatio, Box, Flex, Grid, Link, Text } from "theme-ui"

import Layout from "../components/Layout"
import SoldOut from "../components/SoldOut"

export const query = graphql`
  query {
    allSanityShopifyProduct(filter: {slug: {current: {nin: ["crazy-88-print-a2", "crazy-88-print-a3"]}}}) {
      nodes {
        id
        heading
        colour
        productType
        maxVariantPrice
        variants {
          shopifyVariantID
          title
          sourceData {
            availableForSale
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`

const SalePage = ({
  data: {
    allSanityShopifyProduct: { nodes: products },
  },
}) => {
  return (
    <Layout>
      <Box bg="background" px={[24, "10%"]} py={[4, 5]}>
        <Grid gap={4} columns={[1, 2, 3]}>
          {products.map(
            ({
              id,
              heading,
              productType,
              colour,
              maxVariantPrice,
              slug,
              images,
              variants,
            }) => {
              const price = new Intl.NumberFormat("en-AU", {
                style: "currency",
                currency: "AUD",
              }).format(maxVariantPrice)
              const hasVariants = variants.length > 1
              const isVariantsOutOfStock =
                hasVariants &&
                variants.every((x) => x.sourceData.availableForSale === false)
              const isOutOfStock =
                (!hasVariants && !variants[0].sourceData.availableForSale) ||
                isVariantsOutOfStock

              return (
                <Box
                  key={id}
                  sx={{
                    color: "text",
                    "&:hover": {
                      color: "highlight",
                      span: {
                        color: "primary",
                      },
                    },
                  }}
                >
                  <AspectRatio ratio={1440 / 1652}>
                    <Link as={GatsbyLink} to={`/products/${slug.current}`}>
                      <SwipeableViews disableLazyLoading enableMouseEvents>
                        {images.map((image, idx) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Image key={idx} fluid={image.asset.fluid} />
                        ))}
                      </SwipeableViews>
                      {isOutOfStock && (
                        <Flex
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: "rgba(2, 2, 2, 0.4)",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SoldOut width="100%" />
                        </Flex>
                      )}
                    </Link>
                  </AspectRatio>

                  <Link
                    as={GatsbyLink}
                    to={`/products/${slug.current}`}
                    sx={{
                      textDecoration: "none",
                      textAlign: "center",
                      textTransform: "uppercase",
                      display: "block",
                      fontSize: 0,
                      color: "inherit",
                      fontWeight: "bold",
                      padding: "20px 10px",
                      transition: "color 0.3s ease",
                    }}
                  >
                    {heading} {productType} / {colour}
                    <Text
                      as="span"
                      sx={{
                        color: "gray",
                        marginLeft: "10px",
                        display: "inline-block",
                        transition: "color 0.3s ease",
                      }}
                    >
                      {price}
                    </Text>
                  </Link>
                </Box>
              )
            }
          )}
        </Grid>
      </Box>
    </Layout>
  )
}

export default SalePage
